
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";
@import "@/assets/styles/sections.scss";
@import "@/assets/styles/modal.scss";

.options-select {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    padding-right: 1rem;
  }
  .select {
    select {
      display: inline-flex;
      outline: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      height: 2.5rem;
      background-color: #fff;
      border-color: #dbdbdb;
      border-radius: 4px;
      color: #363636;
      max-width: 100%;
      min-width: 150px;
      width: auto;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      padding: 0.25rem 1rem;
      line-height: 1.5;
    }
  }
}

.graphs {
  display: grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 2rem;
}

.query-graph {
  margin-bottom: 3rem;
}
