
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";

.custom-graph {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: auto 1fr;
  grid-template-columns: 3fr 1fr 1fr;
  grid-template-areas:
    "graph select select"
    "graph options options";
  .graph-area {
    grid-area: graph;
  }
  .options-select {
    justify-content: flex-start;
    grid-area: select;
  }
  // .subtitle {
  //   font-size: 1rem;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   font-weight: 600;
  //   color: #484846;
  //   grid-area: subtitle;
  // }
  .graph-options-container {
    grid-area: options;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    align-items: flex-start;
    justify-content: center;
    .graph-options {
      grid-area: unset;
    }
  }
}
