
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";
@import "@/assets/styles/sections.scss";
@import "@/assets/styles/modal.scss";

.field {
  display: flex;
  flex-flow: column;
  margin-bottom: 1rem;
  label {
    font-weight: 600;
    font-size: 0.9rem;
    padding-bottom: 0.5rem;
  }
}

span.icon {
  bottom: 0rem;
}

.modal-content.section-data {
  // min-width: 90vw;
  width: 400px;
  // min-width: 400px;
  // max-width: 0;
  display: grid;
  grid-template-areas:
    "title title"
    "grades criteria";
  column-gap: 0.5rem;
  transition: all 300ms;
}

.modal-content.section-data > .title {
  grid-area: title;
  margin-bottom: 1rem;
}

.sections-container {
  grid-area: grades;
  padding: 0.5rem;
}
.section-criteria {
  grid-area: criteria;
  padding: 0.5rem;
  ul {
    list-style: none;
    padding-inline-start: 1rem;
    span {
      font-size: 1rem;
      text-decoration: underline;
    }
    li {
      font-size: 0.875rem;
      padding: 0.5rem 0;
    }
  }
}

.section-criteria > p {
  text-align: center;
  font-size: 1.1rem;
  font-weight: 600;
}

.section-comments {
  font-size: 0.9rem !important;
  text-align: left;
  padding-top: 1rem;
  font-size: 400;
  font-style: italic;
}

.section-comments::before {
  content: "Comments: ";
  font-style: normal;
}

.section-item {
  width: 100%;
  display: grid;
  grid-template-columns: 110px 1fr;
  column-gap: 0.5rem;
  div {
    padding: 0.25rem;
    display: flex;
    align-items: center;
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      min-width: 36px;
      padding: 6px 8px;
      border-radius: 30px;
      text-transform: uppercase;
      background: #f2f2f2;
    }
  }
  .section-id {
    justify-self: center;
  }
  .section-grade {
    padding-left: 1rem;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
// div.select {
//   padding-left: 0.5rem;
// }
.select select {
  outline: 0;
  // -webkit-appearance: none;
  // -moz-appearance: none;
  // appearance: none;
  height: 100%;
  // background-color: unset;
  // border: unset;
  border-radius: 4px;
  color: #363636;
  width: 100%;
  font-size: 1rem;
  padding: 4px;
  // margin-right: 10px;
  font-family: AllRoundGothicBook;
  font-weight: 600;
  // text-align: center;
  // text-align-last: center;
  // -moz-text-align-last: center;
}

td.align-left {
  padding-left: 1rem;
  text-align: left;
}
// .select select:focus {
//   min-width: 150px;
//   width: auto;
// }
