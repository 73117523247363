input,
input::placeholder {
  font-family: AllRoundGothicBook;
}

input:focus,
input:active {
  outline: none;
  border-radius: 6px;
}

.field {
  position: relative;
  width: 100%;
}

.input {
  height: 2.5rem;
  line-height: 1.5rem;
  background-color: white;
  border: 1px solid $black-30pc;
  border-radius: 99px;
  font-size: $font1x;
  max-width: 100%;
  width: 100%;
  transition: border-radius 150ms ease-in-out;
}

.input.has-icon {
  padding-left: 2.5rem;
  box-sizing: border-box;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon.input-icon {
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  padding-left: 4px;
  i,
  svg {
    font-size: 1.25rem;
    color: $black-30pc;
    fill: $fill-grey;
  }
}

p.link {
  user-select: none;
  color: $brand-green;
  margin-top: $margin1x;
  a,
  i {
    cursor: pointer;
    margin-right: 10px;
    font-weight: 600;
  }
  a:hover {
    text-decoration: underline;
  }
  &--back {
    color: $black-50pc;
  }
}

.link.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
  font-weight: 400;
}

.table {
  border-collapse: collapse;
  border-radius: $content-border-radius;
  border: $content-border;
  overflow: hidden;
  width: 100%;
}

.table tr {
  height: 2rem;
}

.table td,
.table th {
  text-align: center;
  // padding: 4px;
}

.table thead {
  background: $table-header;
  // border-bottom-left-radius: 0;
  // border-bottom-right-radius: 0;
}

.table tr:nth-child(even) {
  background: #f8f8f8;
}

.table tr td:hover {
  cursor: pointer;
}

.table tr.selected {
  background: rgba(95, 174, 135, 0.25);
  // box-shadow: $box-shadow;
}

.button {
  padding: 0.5rem 1rem;
  background: $brand-green;
  border: none;
  border-radius: 99px;
  color: white;
  font-family: inherit;
  font-size: inherit;
  font-weight: 600;
  cursor: pointer;
}

.button.secondary {
  background: unset;
  color: $black-50pc;
}

.graph-container {
  grid-area: reports;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100%;
  grid-auto-flow: row;
  grid-auto-rows: 100%;
  column-gap: 10px;
  row-gap: 10px;
  overflow-y: scroll;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.graph-report {
  position: relative;
  max-width: 100%;
}

.graph-options {
  grid-area: options;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin: 1rem;
  .option-radio {
    padding: 0.5rem;
    label {
      margin-left: 0.5rem;
    }
  }
}

.table tr {
  height: 3rem;
}
