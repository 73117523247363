
@import "@/assets/styles/variables.scss";
@import "@/style/components.scss";
@import "@/style/sections.scss";

.upload-container {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-rows: 46px repeat(3, auto);
  grid-auto-flow: unset;
  &__title {
    margin: 0;
    font-weight: 600;
    font-size: 1.25rem;
    padding-left: 1rem;
  }
  &__subtitle {
    font-size: 1rem;
    font-weight: 400;
    padding-left: 1rem;
    &--bold {
      font-weight: 600;
    }
  }
  &__dropbox {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    border: 1px dashed lightgrey;
    border-radius: $border-radius;
    padding: 1rem;
    margin: 1rem;
    font-size: 1.25rem;
  }
  .is-active {
    background: #42ab983b;
  }
  .button-row {
    padding-top: $padding1x;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

td.archive {
  width: 3rem;
  // background: rgba(0, 0, 0, 0);
  color: transparent;
  transition: all 200ms ease-in-out;
}

.table-row-enter-active,
.table-row-leave-active {
  transition: all 350ms;
}

.table-row-enter,
.table-row-leave-to {
  opacity: 0;
}

.table-row-move {
  transition: all 300ms;
}

td.archive > .fa-2x {
  font-size: 1.5rem;
}

.table .table-header > th {
  // color: red;
  cursor: pointer;
}

.table > tbody > tr:hover {
  .archive {
    background: rgba(232, 49, 86, 1);
    color: white;
  }
}

.link-hover:hover {
  cursor: pointer;
  text-decoration: underline;
}

.sub-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-area: search;
  .subtitle {
    width: 100%;
  }
}

.search-params {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
}

.search-bar {
  max-width: 200px;
}

button.button:disabled {
  background: lighten($color: $brand-green, $amount: 25%);
  cursor: not-allowed;
}
